/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import {graphql, Link, StaticQuery} from "gatsby"
import bestslug from "../bestslug";

const footerStyle = css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: calc(50vw - 512px);
      padding-right: calc(50vw - 512px);
      background-color: #0088ab;
      height: 44px;
      @media screen and (max-width: 1024px) {
            height: auto;
      }
`;

const listStyle = css`
        list-style: none;
        margin: 0 4rem 0 auto;
        @media screen and (max-width: 1024px) {
            margin: 0;
        }
        li {
            line-height: 44px;
            display: inline-block;
            padding-left: 4rem;
            @media screen and (max-width: 1024px) {
                display: block;
                padding-left: 0;
            }
            a {
                text-decoration: none;
                color: white;
            }
        }
    `;

export default () => (
    <StaticQuery query={graphql`query {
      allFooterMenuJson {
        edges {
          node {
            alternative_id
            linkTitle
            staticPageId
          }
        }
      }
      allPagesJsonFixedMarkup {
        edges {
          node {
            alternative_id
            title
          }
        }
      }
  }`} render={ (data) => (<footer css={footerStyle}>
        <ul css={listStyle}>
            {
                data.allFooterMenuJson.edges.map( (link) => {
                    let pageNode = data.allPagesJsonFixedMarkup.edges.find(edge =>
                        edge.node.alternative_id === link.node.staticPageId
                    ).node;
                    return <li key={link.node.alternative_id}><Link to={"/"+bestslug(pageNode.title)}>{link.node.linkTitle}</Link></li>
                })
            }
        </ul>
    </footer>) }
 /> );