/** @jsx jsx */

import {css, jsx} from "@emotion/core";
import {navigate} from "gatsby";

const iconLinkContainer = css`
    text-align: center;
    margin: auto 0;
    cursor: pointer;
   
    div {
        text-decoration: none;
        color: white;
        font-weight: 700;
    }
`;

const iconLinkContainerHome = css`
    ${iconLinkContainer}
    padding-left: 4rem;
`;

const iconLinkContainerHilfe = css`
    ${iconLinkContainer}
    padding-right: 4rem;
`;

const iconBaseStyle = css`
    height: 4rem;
    width: 4rem;
    padding-bottom: 0.4rem;
`;

const homeIconStyle = css`
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' focusable='false' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 72 72' style='enable-background:new 0 0 72 72;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23F8E43E;%7D .st1%7Bfill:%232B2E34;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_5' class='st0' cx='36' cy='36' r='36'/%3E%3Cpath class='st1' d='M35.3,26.2L19.6,40v14.9c0,0.8,0.6,1.4,1.4,1.4l9.6,0c0.8,0,1.4-0.7,1.4-1.4v-8.7c0-0.8,0.6-1.4,1.4-1.4h5.5 c0.8,0,1.4,0.6,1.4,1.4v8.7c0,0.8,0.6,1.5,1.4,1.5c0,0,0,0,0,0l9.6,0c0.8,0,1.4-0.6,1.4-1.4V40L36.7,26.2 C36.3,25.9,35.7,25.9,35.3,26.2L35.3,26.2z M60.3,35.6l-7.2-6.2V16.8c0-0.6-0.5-1.1-1-1.1h-4.8c-0.6,0-1,0.5-1,1.1v6.6l-7.7-6.7 c-1.5-1.3-3.7-1.3-5.2,0L11.7,35.6c-0.4,0.4-0.5,1.1-0.1,1.5c0,0,0,0,0,0l2.2,2.8c0.4,0.5,1,0.5,1.4,0.1c0,0,0,0,0,0l20.1-17.6 c0.4-0.3,0.9-0.3,1.3,0l20.1,17.6c0.4,0.4,1.1,0.3,1.4-0.1c0,0,0,0,0,0l2.2-2.8C60.8,36.7,60.7,36,60.3,35.6 C60.3,35.6,60.3,35.6,60.3,35.6L60.3,35.6z'/%3E%3C/svg%3E") no-repeat;
    background-size: contain;
    ${iconBaseStyle}
`;

const hilfeIconStyle = css`
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72 72'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23f8e43e;%7D.cls-2%7Bfill:%232b2e34;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EHilfe%3C/title%3E%3Cg id='hilfe'%3E%3Ccircle id='Ellipse_5' class='cls-1' cx='36' cy='36' r='36'/%3E%3Cg id='symbol'%3E%3Cpath id='pfad_3' class='cls-2' d='M44.42,56.38A17.34,17.34,0,0,1,38,54.83,39.65,39.65,0,0,1,24,44.61a38.14,38.14,0,0,1-6.76-10.43,17.82,17.82,0,0,1-1.5-5,15.1,15.1,0,0,1,0-2.93,4.43,4.43,0,0,1,1.49-2.78l2.89-2.86a2.91,2.91,0,0,1,4.09-.47,4.78,4.78,0,0,1,.45.45Q27,23,29.38,25.36a2.78,2.78,0,0,1,.36,3.91,3.2,3.2,0,0,1-.25.27c-.94,1-2,2-2.94,3-.52.51-.53.57-.24,1.24a20.53,20.53,0,0,0,3.52,5.18,29.12,29.12,0,0,0,6.41,5.62c.73.46,1.51.83,2.27,1.23.34.18.57,0,.8-.22q1.5-1.52,3-3a3,3,0,0,1,1.6-.9,2.71,2.71,0,0,1,2.51.79c1.73,1.7,3.46,3.41,5.16,5.15a2.78,2.78,0,0,1,.13,3.92l-.11.12c-1.1,1.16-2.25,2.3-3.4,3.41A5.29,5.29,0,0,1,44.42,56.38Z'/%3E%3Cpath id='pfad_2' class='cls-2' d='M56.36,34.74l-3.11.53a20.22,20.22,0,0,0-17-16.53l.43-3.13A23.3,23.3,0,0,1,56.36,34.74Z'/%3E%3Cpath id='pfad_1' class='cls-2' d='M48.1,35.27,45,35.8a11.06,11.06,0,0,0-9.22-9l.42-3.05A13.93,13.93,0,0,1,48.1,35.27Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
  background-size: contain;
  ${iconBaseStyle}
`;

const Header = () =>  {
    return <header id={"header"} >
        <div css={iconLinkContainerHome} onClick={ () => navigate("/") }>
            <div css={homeIconStyle} />
            <div>Start</div>
        </div>
        <div css={iconLinkContainerHilfe} onClick={ () => navigate("/hilfe") }>
            <div css={hilfeIconStyle} />
            <div>Hilfe</div>
        </div>
    </header>
};

export default Header;